<template>
	<div>
		<r-header v-if="canCreateAccount || canCreateRecurrence || canCreatePledge">Before you go...</r-header>
		<v-checkbox v-if="canCreateAccount" v-model="createAccountChecked" class="mt-0 mb-3" label="Create an account for faster giving." persistent-hint hint="You can see a record of all gifts, manage scheduled gifts, and save payment methods."></v-checkbox>
		<RegisterForm v-if="createAccountChecked && canCreateAccount" type="giverSignup"></RegisterForm>
		<v-checkbox v-if="canCreateRecurrence" v-model="makeRecurringChecked" class="mt-0" label="Make this gift again in the future by setting up a scheduled gift."></v-checkbox>
		<Frequency v-if="makeRecurringChecked && canCreateRecurrence"></Frequency>
		<r-btn v-if="makeRecurringChecked && canCreateRecurrence" @click="scheduleRecurrence">Schedule</r-btn>
		<v-checkbox v-for="(availableCampaign, index) in availableCampaigns" :key="index" v-model="availableCampaign.createPledgeCheckbox" dense class="mt-0" :label="`Add a pledge to ${availableCampaign.fundName}.`" @click="createPledgeToRecurrence(availableCampaign)"></v-checkbox>
	</div>
</template>
<script>
import Frequency from '@/components/giving/donorForm/Frequency.vue';
import RegisterForm from '@/components/giving/account/RegisterForm.vue';
import moment from 'moment';
import sum from 'lodash/sum';
import { formatDollars } from '@/helpers/formatters';
export default {
	components: {
		Frequency,
		RegisterForm
	},
	data() {
		return {
			transactionState: _REALM.STORE.transactionStore.state,
			transactionService: _REALM.STORE.transactionStore,
			givingFormState: _REALM.STORE.givingFormStore.state,
			createAccountChecked: false,
			makeRecurringChecked: false,
			layoutState: _REALM.STORE.layoutStore.state,
			addPaymentMethodFormState: _REALM.STORE.paymentMethodStore.state.addPaymentMethodForm,
			accountState: _REALM.STORE.accountStore.state,
			site: _REALM.STORE.siteStore.state.site,
			availableCampaigns: []
		};
	},
	computed: {
		canCreateAccount() {
			return _REALM.STORE.givingFormStore.canCreateAccount();
		},
		canCreateRecurrence() {
			return _REALM.STORE.givingFormStore.canCreateRecurrence();
		},
		canCreatePledge() {
			return _REALM.STORE.givingFormStore.canCreatePledge(this.availableCampaigns);
		}
	},
	created() {
		this.setupAccountSignup();
		this.initMakeRecurrenceData();
		this.listAvailableCampaigns();
	},
	methods: {
		setupAccountSignup() {
			_REALM.EVENT.on(this, 'account-registered', this.accountRegistered);
			this.accountState.newAccount = {
				name: _REALM.STORE.givingFormStore.state.transactionAccount.label,
				email: _REALM.STORE.givingFormStore.state.transactionAccount.primaryEmail
			};
		},
		createPledgeToRecurrence(campaign) {
			var self = this;
			var args = self.buildAddPledgeArgs(campaign);
			_REALM.TOOLS.confirm(
				`I want to pledge ${formatDollars(args.Amount, { addSymbol: true })}${self.pledgeFrequency(_REALM.STORE.transactionStore.state.transaction.frequencyType)} to ${campaign.fundName}.
				
				Pledge Details
				Pledge Start Date: ${args.StartDate}
				Pledge End Date: ${args.StopDate}`,
				function() {
					self.addPledgeToRecurrence(campaign);
				},
				function() {
					campaign.createPledgeCheckbox = false;
				}
			);
		},
		pledgeFrequency(frequencyType) {
			if (frequencyType === _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.ONCE) {
				return '';
			} else if (frequencyType === _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.WEEKLY) {
				return ' weekly';
			} else if (frequencyType === _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.EVERY_OTHER_WEEK) {
				return ' every two weeks';
			} else if (frequencyType === _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.MONTHLY) {
				return ' monthly';
			} else if (frequencyType === _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.TWICE_A_MONTH) {
				return ' twice a month';
			} else if (frequencyType === _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.EVERY_THREE_MONTHS) {
				return ' every three months';
			}
		},
		parseSource() {
			var isMobile = _REALM.TOOLS.browser().mobile;
			var isText = _REALM.STORE.transactionStore.state.transaction.integrationType === _REALM.STORE.transactionStore.enums.IntegrationType.TEXT;
			var isEmbed = _REALM.STORE.transactionStore.state.transaction.integrationType === _REALM.STORE.transactionStore.enums.IntegrationType.EMBED;

			return isMobile ? 'Mobile' : isText ? 'Text' : isEmbed ? 'Embed' : 'Online';
		},
		buildAddPledgeArgs(campaign) {
			var self = this;

			var amounts = _REALM.STORE.transactionStore.state.transaction.fundAmountGroups
				.filter(function(a) {
					return a.fundId == campaign.fundId;
				})
				.map(function(obj) {
					return obj.amount;
				});

			return {
				Id: null,
				CampaignId: campaign.campaignId,
				IndividualId: _REALM.STORE.givingFormStore.state.transactionAccount.individualId,
				Amount: sum(amounts),
				Frequency: _REALM.STORE.transactionStore.state.transaction.frequencyType,
				Count: _REALM.STORE.transactionStore.state.transaction.numberOfTimes,
				StartDate: _REALM.STORE.transactionStore.state.transaction.giftDate.format('MM/DD/YYYY'),
				StopDate:
					_REALM.STORE.transactionStore.state.transaction.lastPaymentDate && moment(_REALM.STORE.transactionStore.state.transaction.lastPaymentDate).isBefore(campaign.stopDate)
						? moment(_REALM.STORE.transactionStore.state.transaction.lastPaymentDate).format('MM/DD/YYYY')
						: moment(campaign.stopDate).format('MM/DD/YYYY'),
				isNew: true,
				Source: self.parseSource()
			};
		},
		addPledgeToRecurrence(campaign) {
			var self = this;
			var args = self.buildAddPledgeArgs(campaign);
			self.availableCampaigns = [];
			_REALM.STORE.pledgeStore
				.savePledge(args)
				.then(function() {
					_REALM.TOAST.success(`Your pledge has been added.`);
				})
				.finally(function() {
					self.listAvailableCampaigns();
				});
		},
		listAvailableCampaigns() {
			var self = this;
			if (!_REALM.STORE.accountStore.isLoggedIn() || _REALM.STORE.transactionStore.state.transaction.recurrenceId == null) {
				return;
			}

			_REALM.STORE.campaignStore.listAvailableCampaigns({ individualId: self.givingFormState.transactionAccount.individualId }).then(function(availableCampaigns) {
				self.availableCampaigns = availableCampaigns;
			});
		},
		accountRegistered() {
			_REALM.TOAST.success(`Your account has been created. You will receive an email shortly asking you to confirm your email address.`);
			_REALM.STORE.transactionStore.state.transaction.donorHasAccount = true;

			if (_REALM.STORE.pledgeStore.state) {
				_REALM.VM.$set(_REALM.STORE.pledgeStore.state.pledge, 'donorSentAccountInvite', true);
			}
		},
		initMakeRecurrenceData() {
			if (this.canCreateRecurrence) {
				this.transactionState.transaction.frequencyType = this.transactionService.enums.mint_Recurrence_FrequencyType.WEEKLY;
				this.transactionState.transaction.giftDate = moment().add(1, 'week');
			}
		},
		scheduleRecurrence() {
			if (!_REALM.STORE.transactionStore.validateTransactionStartDate()) {
				return false;
			}

			_REALM.LOADER.show();
			_REALM.STORE.transactionStore.processTransaction().finally(function() {
				_REALM.LOADER.hide();
			});
		}
	}
};
</script>
