<template>
	<div>
		<GiveIntro ref="giveIntro" />
		<PaymentMethod :before-submit="beforeSubmit" />
	</div>
</template>

<script>
import PaymentMethod from '@/components/giving/donorForm/PaymentMethod.vue';
import GiveIntro from '@/components/giving/donorForm/GiveIntro';

export default {
	components: {
		GiveIntro,
		PaymentMethod
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			transactionState: _REALM.STORE.transactionStore.state
		};
	},
	methods: {
		beforeSubmit() {
			return this.$refs.giveIntro.validateForm();
		}
	}
};
</script>
