<template>
	<v-container fluid class="px-0 pb-0 input-grid">
		<v-row v-if="!isThankYouPage" :dense="$vuetify.breakpoint.xsOnly">
			<v-col>
				<v-btn-toggle v-model="multipleTimeSelected" mandatory large class="toggle-btn" :class="{ 'small-btn': $vuetify.breakpoint.xsOnly }">
					<v-btn :disabled="editingRecurrence" :value="false" class="default-input-height">Give Once</v-btn>
					<v-btn :disabled="editingRecurrence" :value="true" class="default-input-height">Give Multiple Times</v-btn>
				</v-btn-toggle>
			</v-col>
		</v-row>

		<v-row v-if="multipleTimeSelected || isThankYouPage" :dense="$vuetify.breakpoint.xsOnly">
			<v-col>
				<v-btn-toggle v-if="$vuetify.breakpoint.mdAndUp" v-model="transactionState.transaction.frequencyType" mandatory large>
					<v-btn v-for="giftFrequency in giftFrequencies" :key="giftFrequency.Value" :disabled="editingRecurrence" height="48" :value="giftFrequency.Value" :large="$vuetify.breakpoint.smAndUp">
						{{ giftFrequency.Text | digitWordsToNumbers }}
					</v-btn>
				</v-btn-toggle>
				<r-select-list v-else v-model="transactionState.transaction.frequencyType" outlined :options="giftFrequenciesMobileOptions" label="Frequency"></r-select-list>
			</v-col>
		</v-row>

		<v-row v-if="transactionState.transaction.frequencyType === transactionEnums.mint_Recurrence_FrequencyType.TWICE_A_MONTH" :dense="$vuetify.breakpoint.xsOnly">
			<v-col>
				<r-select-list v-model="transactionState.transaction.firstDayOfMonth" :options="twiceAMonthDay1" required outlined label="First day"></r-select-list>
			</v-col>
			<v-col>
				<r-select-list v-model="transactionState.transaction.secondDayOfMonth" :options="twiceAMonthDay2" required outlined label="Second day"></r-select-list>
			</v-col>
		</v-row>

		<v-row :dense="$vuetify.breakpoint.xsOnly">
			<v-col>
				<r-date-picker v-model="transactionState.transaction.giftDate" :label="startDateLabel" :disabled="recurrenceHasProcessed" required outlined></r-date-picker>
			</v-col>
			<v-col v-if="isRecurrence && !stopGivingAfterVisible" cols="auto">
				<r-btn large class="default-input-height" color="default" @click="addEndDate">+ END</r-btn>
			</v-col>
		</v-row>

		<v-row v-if="stopGivingAfterVisible" :dense="$vuetify.breakpoint.xsOnly">
			<v-col>
				<r-select-list v-model="transactionState.transaction.completionType" :options="completionTypeOptions" :disabled="recurrenceHasProcessed" required label="Stop giving after" outlined />
			</v-col>
			<v-col>
				<r-date-picker v-if="transactionState.transaction.completionType === transactionEnums.mint_Recurrence_CompletionType.STOP_DATE" v-model="transactionState.transaction.stopDate" required outlined label="End date" />
				<r-text-input v-if="transactionState.transaction.completionType === transactionEnums.mint_Recurrence_CompletionType.NUMBER_OF_TIMES" v-model="transactionState.transaction.numberOfTimes" required outlined type="number" label="Number of times" />
			</v-col>
			<v-col cols="auto" class="d-flex align-start">
				<div class="default-input-height d-flex align-center">
					<r-btn small fab color="default" @click="transactionState.transaction.completionType = 0">
						<v-icon>close</v-icon>
					</r-btn>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import moment from 'moment';
import _each from 'lodash/each';

export default {
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			transactionState: _REALM.STORE.transactionStore.state,
			transactionStore: _REALM.STORE.transactionStore,
			twiceAMonthDay1: [],
			transactionEnums: _REALM.STORE.transactionStore.enums,
			multipleTimeSelected: false
		};
	},
	computed: {
		editingRecurrence() {
			return _REALM.STORE.transactionStore.isEditingRecurrence() && !_REALM.STORE.givingFormStore.state.editingRecurrenceIsComplete;
		},
		isThankYouPage() {
			return this.givingFormState.currentDonorPage === 'ThankYou';
		},
		recurrenceHasProcessed() {
			return this.transactionState.transaction.recurrenceId !== null && this.transactionState.transaction.currentCount > 0;
		},
		startDateLabel() {
			if (this.multipleTimeSelected) {
				return 'Start date';
			}

			return 'Gift date';
		},
		isRecurrence() {
			return this.multipleTimeSelected;
		},
		stopGivingAfterVisible() {
			return this.multipleTimeSelected && this.transactionState.transaction.completionType !== this.transactionEnums.mint_Recurrence_CompletionType.UNTIL_I_CANCEL;
		},
		twiceAMonthDay2() {
			return this.buildTwiceAMonthDays(2);
		},
		completionTypeOptions() {
			var selectList = [];
			var completionTypeEnums = _REALM.TOOLS.buildEnumSelectList(this.transactionEnums.mint_Recurrence_CompletionType);

			_each(completionTypeEnums, function(completionTypeEnum) {
				var text = completionTypeEnum.text;
				if (completionTypeEnum.text === 'UNTIL_I_CANCEL') {
					return;
				} else if (completionTypeEnum.text === 'NUMBER_OF_TIMES') {
					text = '# of times';
				} else if (completionTypeEnum.text === 'STOP_DATE') {
					text = 'Date';
				}

				selectList.push({
					text: text,
					value: completionTypeEnum.value
				});
			});

			return selectList;
		},
		giftFrequencies() {
			const giftFrequencies = Object.assign({}, STRATUS.EnumImports.RecurringGiftFrequency);
			delete giftFrequencies.OneTime;
			return giftFrequencies;
		},
		giftFrequenciesMobileOptions() {
			return _REALM.TOOLS.buildSelectList(this.giftFrequencies, 'Value', 'Text');
		}
	},
	watch: {
		multipleTimeSelected(val) {
			this.transactionState.transaction.isRecurrence = val;
			if (this.transactionState.transaction.isRecurrence && this.transactionState.transaction.frequencyType === this.transactionEnums.mint_Recurrence_FrequencyType.ONCE) {
				this.transactionState.transaction.frequencyType = this.transactionEnums.mint_Recurrence_FrequencyType.WEEKLY;
			} else if (!this.transactionState.transaction.isRecurrence) {
				this.transactionState.transaction.frequencyType = this.transactionEnums.mint_Recurrence_FrequencyType.ONCE;
			}
		},
		'transactionState.transaction.giftDate'(val) {
			this.transactionState.transaction.isRecurrence = this.multipleTimeSelected || val.isAfter(moment());
		},
		'transactionState.transaction.frequencyType'(val, oldVal) {
			this.multipleTimeSelected = val !== this.transactionEnums.mint_Recurrence_FrequencyType.ONCE;
			if (this.multipleTimeSelected) {
				var oldYearly = this.getNumberOfTimes(oldVal);
				if (oldYearly == this.transactionState.transaction.numberOfTimes) {
					this.transactionState.transaction.numberOfTimes = this.getNumberOfTimes(val);
				}
			}
		}
	},
	created() {
		this.twiceAMonthDay1 = this.buildTwiceAMonthDays(1);
		this.multipleTimeSelected = this.transactionState.transaction.frequencyType !== this.transactionEnums.mint_Recurrence_FrequencyType.ONCE;
	},
	methods: {
		addEndDate() {
			this.transactionState.transaction.completionType = this.transactionEnums.mint_Recurrence_CompletionType.STOP_DATE;
		},
		buildTwiceAMonthDays(whichDay) {
			var selectOptions = [];
			var maxDays = whichDay === 1 ? 30 : 31;

			for (var i = 1; i <= maxDays; i++) {
				var dayInFormat = moment()
					.month(0) //Need to set month to Jan to ensure 31 days
					.date(i)
					.format('Do');

				if (i > this.transactionState.transaction.firstDayOfMonth || whichDay === 1) {
					selectOptions.push({
						value: i,
						text: dayInFormat
					});
				}
			}

			return selectOptions;
		},
		getNumberOfTimes(frequency) {
			if (frequency == this.transactionEnums.mint_Recurrence_FrequencyType.EVERY_OTHER_WEEK) {
				return 26;
			} else if (frequency == this.transactionEnums.mint_Recurrence_FrequencyType.MONTHLY) {
				return 12;
			} else if (frequency == this.transactionEnums.mint_Recurrence_FrequencyType.TWICE_A_MONTH) {
				return 24;
			} else if (frequency == this.transactionEnums.mint_Recurrence_FrequencyType.EVERY_THREE_MONTHS) {
				return 4;
			}

			return 52;
		}
	}
};
</script>
