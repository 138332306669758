<template>
	<div class="d-flex">
		<v-container fluid class="px-0 py-3">
			<v-row :dense="isDense">
				<v-col cols="12" :sm="columns.amount">
					<r-text-input v-model="amountGroup.amount" :disabled="isAmountDisabled" class="mb-n1 text-right" :autofocus="computedAutoFocus" hide-details="auto" large outlined required type="atm" placeholder="0.00" label="Amount" :prefixColor="givingFormState.givingForm.buttonColor" />
				</v-col>
				<v-col v-if="showFundSelect" cols="12" :sm="columns.fund">
					<r-select-list v-model="amountGroup.fundId" class="mb-n1" :options="selectableFunds" hide-details="auto" outlined required label="Fund" />
				</v-col>
				<v-col v-if="showMemoInput" cols="12" :sm="columns.memo">
					<r-text-input v-if="predefinedMemos.length === 0" v-model="amountGroup.memo" class="mb-n1" hide-details="auto" outlined maxlength="50" label="Memo" />
					<r-select-list v-if="predefinedMemos.length > 0" v-model="amountGroup.memo" class="mb-n1" hide-details="auto" outlined :options="predefinedMemos" label="Memo" />
				</v-col>
			</v-row>
		</v-container>

		<div v-if="transactionState.transaction.amountsList.length > 1" class="mt-3 ml-2 default-input-height d-flex align-center">
			<r-btn small fab color="default" @click="transactionStore.removeAmountGroup(amountGroupIndex)">
				<v-icon>close</v-icon>
			</r-btn>
		</div>
	</div>
</template>

<script>
import _each from 'lodash/each';
import _find from 'lodash/find';
import _orderBy from 'lodash/orderBy';

export default {
	props: ['amountGroup', 'amountGroupIndex'],
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			fundState: _REALM.STORE.fundStore.state,
			transactionState: _REALM.STORE.transactionStore.state,
			transactionStore: _REALM.STORE.transactionStore
		};
	},
	computed: {
		columns() {
			return {
				amount: this.showFundSelect || this.showMemoInput ? 4 : 12,
				fund: 8,
				memo: this.showFundSelect ? 12 : 8
			};
		},
		isAmountDisabled() {
			const fundAmountInQueryParams = _REALM.VM.$route.query.hasOwnProperty('fundId') && _REALM.VM.$route.query.hasOwnProperty('amount') && _REALM.VM.$route.query.fundId === this.amountGroup.fundId && parseFloat(_REALM.VM.$route.query.amount) > 0;

			let fundAmountInQueryGivingFormData = false;
			if (this.givingFormState.givingFormQueryData.hasOwnProperty('amountsList')) {
				const fundAmount = this.givingFormState.givingFormQueryData.amountsList.find(ag => ag.fundId === this.amountGroup.fundId);
				if (fundAmount && fundAmount.amount > 0) {
					fundAmountInQueryGivingFormData = true;
				}
			}

			return _REALM.STORE.layoutStore.isPaymentForm() && (fundAmountInQueryParams || fundAmountInQueryGivingFormData);
		},
		showFundSelect() {
			return _REALM.STORE.givingFormStore.canSelectFunds();
		},
		showMemoInput() {
			return this.selectedFund && (this.selectedFund.isMemoEnabled || this.isAdminManaging);
		},
		isDense() {
			return this.transactionState.transaction.amountsList.length > 1;
		},
		isProcessingCostFund() {
			return _REALM.STORE.givingStore.state.settings.isProcessingCostEnabled && this.amountGroup.fundId === _REALM.STORE.givingStore.state.settings.processingCostFund.fundId;
		},
		isAdminManaging() {
			return _REALM.STORE.givingFormStore.isAdminManaging();
		},
		selectedFund() {
			var self = this;
			var fund = _find(self.fundState.onlineFunds, function(fund) {
				return fund.fundId === self.amountGroup.fundId;
			});

			if (fund) {
				var givingFormFund = _find(self.givingFormState.givingForm.funds, function(givingFormFund) {
					return givingFormFund.fundId === self.amountGroup.fundId;
				});

				if (givingFormFund) {
					fund.isMemoEnabled = givingFormFund.isMemoEnabled;
					fund.predefinedMemos = givingFormFund.predefinedMemos;
				}

				if (!fund.isMemoEnabled && !_REALM.STORE.givingFormStore.isAdminManaging()) {
					self.amountGroup.memo = null;
				}
			}

			return fund;
		},
		predefinedMemos() {
			var memos = [];
			_each(this.selectedFund.predefinedMemos, function(memo) {
				memos.push({
					text: memo,
					value: memo
				});
			});

			return memos;
		},
		selectableFunds() {
			var selectableFunds = _REALM.STORE.fundStore.getSelectableFunds();
			if (selectableFunds.length === 0) {
				// return all funds
				_each(this.fundState.onlineFunds, function(fund) {
					selectableFunds.push({
						text: fund.displayName ? fund.displayName : fund.name,
						value: fund.fundId
					});
				});

				//Admins want funds sorted by name
				if (_REALM.STORE.givingFormStore.isAdminManaging()) {
					selectableFunds = _orderBy(selectableFunds, 'text');
				}
			}

			return selectableFunds;
		},
		computedAutoFocus() {
			// Dont autofocus when previewing giving form, and only autofocus the last input (otherwise issues happen on Safari)
			return !_REALM.STORE.givingFormStore.isPreviewGivingFormMode() && this.transactionState.transaction.amountsList.length - 1 === this.amountGroupIndex;
		}
	},
	watch: {
		selectableFunds() {
			this.setDefaultFund();
		}
	},
	created() {
		return _REALM.STORE.fundStore.listOnline({ filterCampusFunds: !_REALM.STORE.givingFormStore.isAdminManaging() });
	},
	methods: {
		setDefaultFund() {
			if (!this.selectedFund && this.selectableFunds && this.selectableFunds.length > 0 && !this.amountGroup.fundId) {
				this.amountGroup.fundId = this.selectableFunds[0].value;
			}
		}
	}
};
</script>
