<style lang="scss">
.form-header-wrapper {
	.form-header {
		padding: 16px !important;
	}

	.form-header-thank-you-page {
		padding: 56px 48px !important;
	}

	.title-header {
		margin-bottom: 4px !important;
		font-size: 24px !important;
		line-height: 30px !important;
	}

	.sub-title-header {
		font-size: 16px !important;
	}
}
</style>
<template>
	<v-layout :style="headerStyles" class="form-header-wrapper" align-center justify-start>
		<template v-if="showFullHeader">
			<div class="form-header d-flex pa-6">
				<FormLogo form-type="Giving"></FormLogo>
				<div class="ml-5 d-flex flex-column justify-center">
					<div :class="computedFontClass" class="title-header" :style="headerStyles">{{ givingFormState.givingForm.title }}</div>
					<div class="sub-title-header font-weight-thin">{{ givingFormState.givingForm.subtitle }}</div>
					<div class="text-pre-wrap">{{ givingFormState.givingForm.introMessage }}</div>
				</div>
			</div>
		</template>
		<template v-else-if="currentPage == 'ThankYou'">
			<v-flex class="text-center form-header-thank-you-page">
				<h4 v-if="!isAdminManaging" :style="headerStyles">Thank you for{{ transactionState.transaction.recurrenceId ? ' scheduling' : '' }} your {{ transactionType }} of {{ transactionStore.totalGiftAmountWithProcessingCost() | centsToDollars }}</h4>
				<h4 v-if="isAdminManaging" :style="headerStyles">{{ givingFormState.transactionAccount.label }}'s {{ transactionType }} of {{ transactionStore.totalGiftAmountWithProcessingCost() | centsToDollars }} has been {{ giftEnteredWording }}.</h4>
				<h5 v-if="transactionState.transaction.receiptNumber" class="mt-4" :style="headerStyles">Receipt Number: {{ transactionState.transaction.receiptNumber }}</h5>
				<div v-if="transactionState.transaction.isRecurrence && transactionState.transaction.frequencyType != transactionStore.enums.mint_Recurrence_FrequencyType.ONCE && transactionState.transaction.description" class="subheading mt-4">
					{{ transactionState.transaction.description }}
				</div>
				<div v-if="givingFormState.givingForm.receiptMessage" class="subheading mt-4 text-pre-wrap">{{ givingFormState.givingForm.receiptMessage }}</div>
				<div v-if="showEmailLine" class="mt-4">You will receive an email confirmation once this {{ transactionType }} has processed.</div>
				<div v-if="isAdminManaging" class="mt-4">We will send the contributor an email confirmation once this {{ transactionType }} has processed.</div>
				<ActionButton v-if="finishAction !== null" color="default" :font="givingFormState.givingForm.font" class="mt-4" @click="finishAction">Finish</ActionButton>
			</v-flex>
		</template>
		<div v-else></div>
	</v-layout>
</template>

<script>
import ActionButton from '@/components/giving/sharedForms/ActionButton.vue';
import FormLogo from '@/components/giving/sharedForms/FormLogo';

export default {
	components: {
		ActionButton,
		FormLogo
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			transactionState: _REALM.STORE.transactionStore.state,
			transactionStore: _REALM.STORE.transactionStore,
			imageTypes: _REALM.STORE.mediaStore.ImageTypes
		};
	},
	computed: {
		isPaymentRedirect() {
			return _REALM.STORE.layoutStore.isPaymentForm() && this.transactionState.returnUrl;
		},
		finishAction() {
			if (this.isGivingFormEmbed || this.isEngagementAndIOS) {
				return this.closeDonorForm;
			} else if (this.isPaymentRedirect) {
				return this.redirectToReturnUrl;
			} else if (_REALM.VM.$route.query.recurrenceId) {
				const query = { ...this.$route.query };
				delete query.recurrenceId;
				return () => _REALM.VM.$router.push({ name: 'scheduled-gifts', query });
			}

			return null;
		},
		showEmailLine() {
			return !this.isAdminManaging && !(_REALM.VM.$route.query.token && _REALM.STORE.layoutStore.isPaymentForm());
		},
		isGivingFormEmbed() {
			return _REALM.STORE.givingFormStore.isGivingFormEmbed();
		},
		isEngagementAndIOS() {
			return this.isEngagement && _REALM.TOOLS.browser().isIOS;
		},
		isEngagementAndAndroid() {
			return this.isEngagement && _REALM.TOOLS.browser().isAndroid;
		},
		isEngagement() {
			return _REALM.VM.$route.query.isEngagement && _REALM.VM.$route.query.isEngagement.toLowerCase() === 'true';
		},
		isAdminManaging() {
			return _REALM.STORE.givingFormStore.isAdminManaging();
		},
		isRealmEmbed() {
			return _REALM.STORE.transactionStore.enums.IntegrationType.REALM === _REALM.STORE.transactionStore.state.transaction.integrationType;
		},
		headerStyles() {
			return {
				backgroundColor: this.givingFormState.givingForm.headerBackgroundColor,
				color: _REALM.TOOLS.getContrastColor(this.givingFormState.givingForm.headerBackgroundColor)
			};
		},
		computedFontClass() {
			return this.givingFormState.givingForm.font + '-font-family';
		},
		showFullHeader() {
			return !_REALM.STORE.givingFormStore.useDefaultFormStyles() && !this.isEngagementAndAndroid && ['GiveIntro', 'KnownGiver', 'giving-form', 'LandingPage'].includes(this.currentPage);
		},
		currentPage() {
			if (_REALM.STORE.layoutStore.isGivingForm()) {
				return this.givingFormState.currentDonorPage;
			}

			return _REALM.VM.$route.name;
		},
		giftEnteredWording() {
			return this.transactionState.transaction.isRecurrence ? 'scheduled' : 'entered';
		},
		transactionType() {
			return _REALM.STORE.layoutStore.isPaymentForm() ? 'payment' : 'gift';
		}
	},
	methods: {
		closeDonorForm() {
			if (this.isEngagementAndIOS) {
				window.location = _REALM.CONFIG.EngagementRoot + '/giving/history';
			} else {
				_REALM.EVENT.emit('close-donor-form', this.transactionState.transaction);
			}
		},
		redirectToReturnUrl() {
			if (this.transactionState.returnUrl) {
				window.location = this.transactionState.returnUrl;
			}
		}
	}
};
</script>
