<template>
	<v-checkbox v-if="showProcessingCosts" v-model="transactionState.transaction.isProcessingCostEnabled" :label="processingCostLabel" class="mt-0 pt-0">></v-checkbox>
</template>
<script>
import { formatCentsToDollars } from '@/helpers/formatters';
export default {
	data() {
		return {
			givingState: _REALM.STORE.givingStore.state,
			transactionState: _REALM.STORE.transactionStore.state
		};
	},
	computed: {
		processingCostLabel() {
			var recurrenceWording = this.transactionState.transaction.frequencyType !== _REALM.STORE.transactionStore.enums.mint_Recurrence_FrequencyType.ONCE ? 'per gift' : '';
			var processingCostAmount = formatCentsToDollars(_REALM.STORE.transactionStore.possibleProcessingCostAmount(), { addSymbol: true });
			var extraWording = `an extra ${processingCostAmount}`;
			return `Contribute ${extraWording} ${recurrenceWording} to help offset processing costs.`;
		},
		showProcessingCosts() {
			const isPaymentForm = _REALM.STORE.layoutStore.isPaymentForm() && !this.transactionState.transaction.isProcessingCostEnabled;
			return this.givingState.settings.isProcessingCostEnabled && _REALM.STORE.transactionStore.possibleProcessingCostAmount() > 0 && !isPaymentForm;
		}
	}
};
</script>
