<template>
	<v-layout justify-center row wrap>
		<v-flex xs12>
			<h5 class="secondary--text text-center">
				This link has expired.
			</h5>
		</v-flex>
		<v-flex xs12 text-center>
			Send another text to receive a new giving link.
		</v-flex>
	</v-layout>
</template>
<script>
export default {
	components: {}
};
</script>
