<template>
	<v-container class="px-0 pt-0 input-grid">
		<v-row :dense="$vuetify.breakpoint.xsOnly">
			<v-col class="d-flex align-items-center">
				<v-checkbox v-model="giftIsMemorial" label="Dedicate this gift in memory or in honor of someone" class="mt-0 pt-0 d-flex align-center" :hide-details="true"></v-checkbox>
			</v-col>
		</v-row>
		<v-row v-if="giftIsMemorial" :dense="$vuetify.breakpoint.xsOnly">
			<v-col>
				<r-select-list v-model="transactionState.transaction.memorialId" class="mb-n1" :options="memorialStore.state.selectableGivingFormMemorials" hide-details="auto" outlined required label="In Memory / In Honor" />
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
export default {
	data() {
		return {
			memorialStore: _REALM.STORE.memorialStore,
			givingFormStore: _REALM.STORE.givingFormStore,
			transactionState: _REALM.STORE.transactionStore.state,
			selectValue: null
		};
	},
	computed: {
		giftIsMemorial: {
			get() {
				return this.transactionState.transaction.memorialId !== null;
			},
			set(val) {
				this.transactionState.transaction.memorialId = val ? this.selectValue : null;
			}
		}
	},
	watch: {
		'transactionState.transaction.memorialId': function(val) {
			if (val) {
				this.selectValue = val;
			}
		}
	},
	created() {
		this.selectValue = this.transactionState.transaction.memorialId ?? this.memorialStore.state.selectableGivingFormMemorials[0].value;
	}
};
</script>
