<template>
	<v-layout>
		<v-flex text-right class="pb-4 pb-sm-6 pt-3">
			<r-btn class="default-input-height" large color="default" @click="transactionStore.addAmountGroup">+ Another Fund</r-btn>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	data() {
		return {
			transactionStore: _REALM.STORE.transactionStore
		};
	}
};
</script>
