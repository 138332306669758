<template>
	<v-layout>
		<v-flex>
			<component :is="givingFormState.currentDonorPage" v-if="givingFormState.currentDonorPage !== 'PaymentMethod'"></component>
			<PaymentMethod v-if="includePaymentMethod" v-show="givingFormState.currentDonorPage === 'PaymentMethod'"></PaymentMethod>
		</v-flex>
	</v-layout>
</template>
<script>
import GiveIntro from '@/components/giving/donorForm/GiveIntro.vue';
import PaymentMethod from '@/components/giving/donorForm/PaymentMethod.vue';
import KnownGiver from '@/components/giving/sharedForms/KnownGiver.vue';
import ThankYou from '@/components/giving/donorForm/ThankYou.vue';
import TextExpired from '@/components/giving/donorForm/TextExpired.vue';
import LandingPage from '@/components/giving/donorForm/LandingPage.vue';
import SinglePageForm from '@/components/giving/donorForm/SinglePageForm.vue';
import ErrorMessagePage from '@/components/giving/donorForm/ErrorMessagePage.vue';
import moment from 'moment';

export default {
	components: {
		GiveIntro,
		PaymentMethod,
		KnownGiver,
		ThankYou,
		TextExpired,
		LandingPage,
		SinglePageForm,
		ErrorMessagePage
	},
	data() {
		return {
			transactionState: _REALM.STORE.transactionStore.state,
			givingFormState: _REALM.STORE.givingFormStore.state,
			givingSettings: _REALM.STORE.givingStore.state.settings
		};
	},
	computed: {
		isGivingForm() {
			return _REALM.STORE.layoutStore.isGivingForm();
		},
		includePaymentMethod() {
			return this.givingSettings.isGivingEnabled && !this.givingFormState.textTokenExpired && _REALM.STORE.layoutStore.isGivingForm() && !_REALM.STORE.givingFormStore.isSinglePageForm();
		}
	},
	watch: {
		'givingFormState.currentDonorPage'() {
			if (_REALM.TOOLS.inIframe()) {
				_REALM.TOOLS.scrollTopIframe();
			} else {
				this.$vuetify.goTo('body');
			}
		}
	},
	created() {
		if (_REALM.STORE.memorialStore.siteHasMemorials()) {
			_REALM.STORE.memorialStore.buildSelectableGivingFormMemorials();
		}
	},
	mounted() {
		if (_REALM.STORE.layoutStore.isPaymentForm() && _REALM.STORE.fundStore.getSelectableFunds().length === 0 && !this.givingFormState.givingFormError) {
			this.givingFormState.givingFormError = 'NoFund';
		}

		this.transactionState.giftStartTime = moment();
		if (!this.givingSettings.isGivingEnabled && !_REALM.STORE.givingFormStore.isPreviewGivingFormMode()) {
			this.givingFormState.currentDonorPage = 'LandingPage';
		} else if (this.givingFormState.textTokenExpired && !_REALM.STORE.givingFormStore.isPreviewGivingFormMode()) {
			this.givingFormState.currentDonorPage = 'TextExpired';
		} else if (this.givingFormState.givingFormError) {
			this.givingFormState.currentDonorPage = 'ErrorMessagePage';
		} else if (_REALM.STORE.givingFormStore.isSinglePageForm()) {
			this.givingFormState.currentDonorPage = 'SinglePageForm';
		} else {
			this.givingFormState.currentDonorPage = 'GiveIntro';
		}

		var description = _REALM.STORE.transactionStore.isEditingRecurrence() ? 'Edit Gift Initiated' : 'Add Gift Initiated';
		description = _REALM.STORE.givingFormStore.isAdminManaging() ? 'Admin ' + description : description;
	}
};
</script>
