<template>
	<div>
		<h4 class="mb-3">Error</h4>
		<h6>{{ errorMessage }}</h6>
		<v-layout>
			<v-flex text-right>
				<r-btn v-if="cancelAction !== null" color="secondary" class="mr-2" @click="cancelAction">Close</r-btn>
			</v-flex>
		</v-layout>
	</div>
</template>
<script>
export default {
	data() {
		return {
			error: _REALM.STORE.givingFormStore.state.givingFormError
		};
	},
	computed: {
		isGivingFormEmbed() {
			return _REALM.STORE.givingFormStore.isGivingFormEmbed();
		},
		isGivingFormView() {
			return _REALM.VM.$route.params.givingFormUrl !== undefined;
		},
		cancelAction() {
			if (this.isGivingFormEmbed || !this.isGivingFormView) {
				return this.closeDonorForm;
			} else if (_REALM.STORE.layoutStore.isPaymentForm() && !this.isGivingFormEmbed && window.history.length > 1) {
				return () => window.history.back();
			}

			return null;
		},
		errorMessage() {
			switch (this.error) {
				case 'InvoicePaid':
					return 'This invoice has already been paid.';

				case 'ExpiredToken':
					return 'Invalid token: Transaction token is expired.';

				case 'InvalidToken':
					return 'Invalid token: Failed to validate transaction token.';

				case 'NoFund':
					return 'No active online fund is associated with this form.';

				case 'PaymentFundType':
					return 'Fund type is incompatible with Payment form';

				case 'ProgramId':
					return 'Invalid transaction data: Could not find fund matching the Program Id.';

				case 'PaymentNotifyUrl':
				case 'InvoiceId':
				case 'PayorContactId':
					return `Invalid transaction data: ${this.error} is invalid.`;

				case 'Amount':
					return 'Invalid transaction data: Amount cannot be less than $3.00.';

				case 'GivingFormData':
				default:
					return 'Invalid transaction data: Failed to load giving form data.';
			}
		}
	},
	methods: {
		closeDonorForm() {
			_REALM.EVENT.emit('close-donor-form');
		}
	}
};
</script>
