<template>
	<div>
		<DonorTitle class="mb-4" :font="givingFormState.givingForm.font">{{ computedHeader }}</DonorTitle>
		<r-select-list v-if="paymentMethodOptions.length > 0" v-model="selectedPaymentMethodId" :options="paymentMethodOptions" label="Payment method" class="mb-4" outlined></r-select-list>
		<AddPaymentMethodForm v-show="showAddPaymentMethodForm" ref="addPaymentMethodFormRef" :submitMethod="processGift"></AddPaymentMethodForm>
		<ProcessingCost></ProcessingCost>
		<AmountSummary v-if="!isOneTimePaymentOnly" class="mt-1 mt-sm-3" form-type="Giving" :recurrence-count="recurrenceCount" :recurrence-count-loading="fetchingRecurrenceCount" />
		<v-layout class="mt-4">
			<r-btn v-if="!isSinglePageForm" color="default" @click="backPage">Back</r-btn>
			<v-spacer></v-spacer>
			<r-btn v-if="cancelAction !== null" color="default" class="mr-2" @click="cancelAction">Cancel</r-btn>

			<ActionButton :color="givingFormState.givingForm.buttonColor" :font="givingFormState.givingForm.font" @click="processGift">{{ giveButton }}{{ transactionStore.totalGiftAmountWithProcessingCost() | centsToDollars }}</ActionButton>
		</v-layout>

		<r-reason-dialog v-model="reasonDialog" :submitCallback="processGiftReasonCallback"></r-reason-dialog>
	</div>
</template>
<script>
import AddPaymentMethodForm from '@/components/giving/donorForm/AddPaymentMethodForm.vue';
import ActionButton from '@/components/giving/sharedForms/ActionButton.vue';
import ProcessingCost from '@/components/giving/donorForm/ProcessingCost.vue';
import _filter from 'lodash/filter';
import moment from 'moment';
import formatDollars from '@/filters/giving/Dollars';
import fromNow from '@/filters/FromNow';
import DonorTitle from '@/components/giving/sharedForms/DonorTitle.vue';
import AmountSummary from '@/components/giving/sharedForms/AmountSummary';

export default {
	components: {
		AddPaymentMethodForm,
		ActionButton,
		ProcessingCost,
		DonorTitle,
		AmountSummary
	},
	props: {
		beforeSubmit: Function
	},
	data() {
		return {
			givingFormState: _REALM.STORE.givingFormStore.state,
			givingFormStore: _REALM.STORE.givingFormStore,
			transactionState: _REALM.STORE.transactionStore.state,
			transactionStore: _REALM.STORE.transactionStore,
			accountState: _REALM.STORE.accountStore.state,
			accountStore: _REALM.STORE.accountStore,
			paymentMethodState: _REALM.STORE.paymentMethodStore.state,
			selectedPaymentMethodId: null,
			showAddPaymentMethodForm: false,
			reasonDialog: false,
			recurrenceCount: _REALM.STORE.transactionStore.state.transaction.recurrenceCount,
			fetchingRecurrenceCount: false
		};
	},
	computed: {
		isGivingFormEmbed() {
			return _REALM.STORE.givingFormStore.isGivingFormEmbed();
		},
		isSinglePageForm() {
			return _REALM.STORE.givingFormStore.isSinglePageForm();
		},
		isOneTimePaymentOnly() {
			return _REALM.STORE.givingFormStore.isOneTimePaymentOnly();
		},
		isGivingFormView() {
			return _REALM.VM.$route.params.givingFormUrl !== undefined;
		},
		computedHeader() {
			if (_REALM.STORE.givingFormStore.isAdminManaging() && this.givingFormState.transactionAccount.firstName) {
				return 'What is ' + this.givingFormState.transactionAccount.firstName + ' using to give?';
			}

			if (_REALM.STORE.layoutStore.isPaymentForm()) {
				return '';
			}

			var header = 'How would you like to give';

			if (this.givingFormState.transactionAccount.firstName) {
				header += ', ' + this.givingFormState.transactionAccount.firstName;
			}
			header += '?';
			return header;
		},
		paymentMethodOptions() {
			var activePaymentMethods = _filter(this.paymentMethodState.paymentMethods, function(paymentMethod) {
				//CreditCards not allowed
				if (!_REALM.STORE.givingStore.state.settings.allowCreditCard && paymentMethod.paymentMethodType === 'Credit') {
					return false;
				}

				//Debit Cards not allowed
				if (!_REALM.STORE.givingStore.state.settings.allowDebitCard && paymentMethod.paymentMethodType === 'Debit') {
					return false;
				}

				//ACH not allowed
				if (!_REALM.STORE.givingStore.state.settings.allowAch && (paymentMethod.paymentMethodType === 'Checking' || paymentMethod.paymentMethodType === 'Savings')) {
					return false;
				}

				//Filter expired payment methods
				//Checkings/Savings do not have expirationDate
				return paymentMethod.paymentMethodType === 'Checking' || paymentMethod.paymentMethodType === 'Savings' || paymentMethod.expirationDate.diff(moment()) > 0;
			});

			var paymentMethodOptions = _REALM.TOOLS.buildSelectList(activePaymentMethods, 'paymentMethodId', 'description');

			if (paymentMethodOptions.length > 0) {
				paymentMethodOptions.push({
					value: 'enterNewPaymentMethod',
					text: 'Enter a different payment method'
				});
			}

			return paymentMethodOptions;
		},
		giveButton() {
			if (_REALM.STORE.layoutStore.isPaymentForm()) {
				return 'Pay ';
			}

			return this.transactionStore.isEditingRecurrence() ? 'Save ' : 'Give ';
		},
		cancelAction() {
			if (this.isGivingFormEmbed) {
				return this.closeDonorForm;
			} else if (_REALM.STORE.layoutStore.isPaymentForm() && !this.isGivingFormEmbed && window.history.length > 1) {
				return () => window.history.back();
			}

			return null;
		}
	},
	watch: {
		selectedPaymentMethodId(newVal) {
			this.showAddPaymentMethodForm = newVal === 'enterNewPaymentMethod';
			this.transactionState.transaction.paymentMethodId = newVal === 'enterNewPaymentMethod' ? null : newVal;
			if (this.givingFormState.currentDonorPage === 'PaymentMethod') {
				document.activeElement.blur();
			}
		},
		'givingFormState.transactionAccount.individualId'() {
			this.showAddPaymentMethodForm = false;
			this.listPaymentMethods();
		},
		'transactionState.transaction.recurrenceCount'(newValue) {
			this.recurrenceCount = newValue;
		},
		paymentMethodOptions: {
			handler(val) {
				if (!this.selectedPaymentMethodId) {
					this.selectedPaymentMethodId = val && val.length > 0 ? val[0].value : null;
				}

				this.showAddPaymentMethodForm = !val || val.length == 0;
			},
			deep: true
		}
	},
	created() {
		//Always reset paymentMethodId when coming loading form
		_REALM.STORE.transactionStore.state.transaction.paymentMethodId = null;
		this.listPaymentMethods();
	},
	methods: {
		closeDonorForm() {
			_REALM.EVENT.emit('close-donor-form');
		},
		listPaymentMethods() {
			var self = this;
			if (_REALM.STORE.accountStore.isLoggedIn() && !_REALM.STORE.givingFormStore.isTextGiver() && _REALM.STORE.givingFormStore.state.transactionAccount.mintCustomerId) {
				_REALM.STORE.paymentMethodStore.listPaymentMethods(_REALM.STORE.givingFormStore.state.transactionAccount.mintCustomerId);
			} else if (_REALM.STORE.givingFormStore.isTextGiver() && _REALM.STORE.givingFormStore.state.transactionAccount.mintCustomerId) {
				_REALM.STORE.paymentMethodStore.listPaymentMethodsByTextToken(_REALM.VM.$route.query.token);
			} else {
				self.showAddPaymentMethodForm = true;
			}
		},
		processGift() {
			var self = this;

			if (typeof self.beforeSubmit === 'function' && !self.beforeSubmit()) {
				return;
			}

			self.transactionState.transaction.giftDuration = self.calculateGiftTime();

			var continueGiftProcessing = self.completeProcessGift;
			if (_REALM.STORE.givingFormStore.isAdminManaging()) {
				continueGiftProcessing = self.showReasonDialog;
			}

			_REALM.LOADER.show();
			_REALM.STORE.transactionStore
				.getLastGift()
				.then(function() {
					if (self.transactionState.lastGift.lastGiftDate) {
						_REALM.LOADER.hide();
						var message = self.calculateLastGiftMessage();
						_REALM.TOOLS.confirm(message, function() {
							continueGiftProcessing();
						});
					} else {
						continueGiftProcessing();
					}
				})
				.catch(function() {
					continueGiftProcessing();
				});
		},
		calculateGiftTime() {
			var giftDuration = moment.duration(moment() - _REALM.STORE.transactionStore.state.transaction.giftStartTime).asSeconds();
			return giftDuration;
		},
		trackProcessedGift() {
			var description,
				action = 'Gift Processed';

			if (this.transactionStore.isEditingRecurrence()) {
				description = 'Edited a recurring gift.';
			} else if (this.transactionStore.state.transaction.isRecurrence) {
				description = 'Added a recurring gift.';
			} else {
				description = 'Added a one-time gift.';
			}

			if (_REALM.STORE.givingFormStore.isAdminManaging()) {
				action = 'Admin ' + action;
				description = 'Admin ' + description;
			}
		},
		showReasonDialog() {
			_REALM.LOADER.hide();
			if (this.showAddPaymentMethodForm) {
				if (!this.$refs.addPaymentMethodFormRef.$refs.addPaymentMethodForm.validate()) {
					return;
				}
			}
			this.reasonDialog = true;
			_REALM.TOOLS.scrollTopIframe();
			return;
		},
		completeProcessGift() {
			var self = this;
			_REALM.LOADER.show();
			this.addPaymentMethod()
				.then(this.processTransaction)
				.then(this.nextPage)
				.then(function() {
					self.trackProcessedGift();
				})
				.catch(function(err) {
					console.error(err);
				})
				.finally(function() {
					_REALM.LOADER.hide();
				});
		},
		processGiftReasonCallback(reason) {
			this.transactionState.transaction.reason = reason;
			this.completeProcessGift();
		},
		addPaymentMethod() {
			var self = this;

			if (!self.showAddPaymentMethodForm && self.transactionState.transaction.paymentMethodId) {
				return Promise.resolve();
			}

			return this.$refs.addPaymentMethodFormRef.addPaymentMethod().then(function(paymentMethod) {
				self.transactionState.transaction.paymentMethodId = paymentMethod.PaymentMethodId;
				self.transactionState.transaction.isNewPaymentMethod = true;
				return paymentMethod;
			});
		},
		processTransaction() {
			return _REALM.STORE.transactionStore.processTransaction();
		},
		nextPage() {
			if (!this.isGivingFormView) {
				_REALM.EVENT.emit('close-donor-form');
				//TODO Remove not needed
				if (this.transactionState.transaction.isRecurrence) {
					var changeType = this.transactionState.transaction.isUpdateRecurrence ? 'updated' : 'added';
					_REALM.TOAST.success(`Scheduled Gift has been ${changeType} for ${_REALM.STORE.givingFormStore.state.transactionAccount.label}`);
					_REALM.VM.$router.push({
						name: 'admin-scheduled-gifts',
						params: _REALM.VM.$route.params
					});
					_REALM.EVENT.emit('admin-scheduled-gifts-routed');
				} else {
					_REALM.TOAST.success('Gift has processed for ' + _REALM.STORE.givingFormStore.state.transactionAccount.name);
					_REALM.VM.$router.push({
						name: 'admin-giving-history',
						params: _REALM.VM.$route.params
					});

					_REALM.EVENT.emit('admin-giving-history-routed');
				}

				_REALM.STORE.transactionStore.resetTransaction();

				return;
			}
			this.givingFormState.currentDonorPage = 'ThankYou';
		},
		backPage() {
			this.givingFormState.currentDonorPage = 'GiveIntro';
		},
		calculateLastGiftMessage() {
			var self = this;
			var transactionType = _REALM.STORE.layoutStore.isPaymentForm() ? 'payment' : 'gift';
			var amount = formatDollars(self.transactionState.lastGift.amount);
			var timeSinceLastGift = fromNow(self.transactionState.lastGift.lastGiftDate);

			return `A ${transactionType} of ${amount} was made ${timeSinceLastGift} from this device.`;
		}
	}
};
</script>
